import React from 'react'
import privacy from '../Images/suitecase1.png'

function PrivacyFund() {
    return (
        <>
        <section className="privacy-fund">
            <div className="content">
                <div className="privacy-fund-content">
                    <div className="privacy-fund-text">
                        <h1>Sustainability and Growth</h1>
                        <p>
                                A Sustainable WoofConomy
                                <br></br>
                                <p>

                                Engineered from the gound up, our ecosystem is built for sustainability! Our main objective is to rewards to our owners with consistent and reliable
                                revenue of  BUSD.
                                </p> 
                                
                                
                        </p>
                    </div>
                    <div className="privacy-fund-image">
                        <img src={privacy} alt="Mobile" />
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default PrivacyFund
