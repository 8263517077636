import React from 'react'
import Accordian from './Accordian'

function Faq() {
    const Questions = [
        {
            question: "How do I purchase ROTTY",
            answer : "You can purchase ROTTY by clicking this link"
        },
        {
            question: "What is the Slippage",
            answer: "13% slippage should enable your buys to go through"
        },
        {

        question:"Why BUSD as reward?",
        answer:"Because who doesn't love some extra trading cash."

        },
        {
        question: "Is there a cap on earnings?",
        answer: "The only cap of earnings is volume of the token's trading. The earlier you purchase the longer you can earn BUSD."
        },
    ]
    return (
        <section className="Faq" id="faq">
            <div className="content">
                <div className="faq-heading">
                    <h1>FAQ</h1>
                    <p></p>
                </div>
                <Accordian question={Questions[0].question} answer={Questions[0].answer} id="One" />
                <Accordian question={Questions[1].question} answer={Questions[1].answer} id="Two" />
                <Accordian question={Questions[2].question} answer={Questions[2].answer} id="Three" />
                <Accordian question={Questions[3].question} answer={Questions[3].answer} id="Four" />
        
            </div>
        </section>
    )
}

export default Faq
