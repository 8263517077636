import './styles.css';
import React, { Component } from 'react'
import Navbar from './Components/Navbar';
import Mainsection from './Components/Mainsection';
import SaleCountdown from './Components/SaleCountdown';
import TheCoinSection from './Components/TheCoinSection';
import Achievement from './Components/Achievement';
import LastYearSale from './Components/LastYearSale';
import FundRaising from './Components/FundRaising';
import PrivacyFund from './Components/PrivacyFund';
import WalletSection from './Components/WalletSection';
import Faq from './Components/Faq';
import NewsLetter from './Components/NewsLetter';
import Footer from './Components/Footer';
import Rewards from './Components/Rewards';
import Howtobuy from './Components/HowtoBuy';

class App extends Component {
  render() {
    return (
     <>
      <Navbar />
      <Mainsection />
      <SaleCountdown />
     
      <TheCoinSection />
      <PrivacyFund />
      <Rewards />
      <Howtobuy />
 
      <Faq />
 
    
      
 
     </>
    )
  }
}

export default App
