import React from 'react'
import privacy from '../Images/moneymachine.png'

function Rewards() {
    return (
        <>
        <section className="rewards">
            <div className="content">
                <div className="privacy-fund-content">
                    <div className="privacy-fund-text">
                        <h1>More Rewards for our Investors </h1>
                        <p>
                            Almost all Reward Tokens, no matter if the Rewards are in BNB, BUSD or any other BSC Token, skim Rewards from their Holders by not excluding the Deployer and Marketing Wallet from Rewards.
                       </p><p>
                            In Rotty the Deployer and Marketing Wallet are excluded from Rewards. It means, all BUSD Rewards go only to true and real Investors, that results in more Rewards for everyone!
                               
                               
                               
                                
                        </p>
                    </div>
                    <div className="privacy-fund-image">
                        <img src={privacy} alt="Mobile" />
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Rewards
