import React from 'react'
import mainBg from '../Images/rwhead.png'

function Mainsection() {
    return (
        <section className="main-section content" id="mainSection">
        <div className="main-text">
            <div className="heading">
                <h1>
                Introducing Rotty!
                </h1>
                <p>The Newest Pupper Taking on the BSC World. 
                    Rotty comes With 5% BUSD rewards!
                </p>
            </div>
            <div className="buttons">
                <button className="try-for-free">Join Presale</button>
                <button className="white-paper">Chart</button>
            </div>
        </div>  
        <div className="main-image">
            <img src={mainBg} alt="BG" />
        </div>
        </section>
    )
}

export default Mainsection
