import React from 'react'
import marketValue from '../Images/market-value.png';
import verifiedMining from "../Images/verified-mining.png";
import fastestMiner from "../Images/fastest-miner.png";
import secureTransactions from "../Images/secure-transactions.png";

const Feature = (props)=>{
    return (
        <div className="single-feature">
            <img src={props.img} alt="Market Value" />
            <h3>{props.h3}</h3>
            <p>{props.p}</p>
        </div>
    )
}
function TheCoinSection() {
    return (
        <section className="the-coin-section content" id="keyFeatures">
            <div className="coin-heading">
                <h1>Why Rotty</h1>
                <p></p>
            </div>
            <div className="features">
                <Feature 
                    img = {marketValue}
                    h3 = "5% BUSD Rewards"
                    p = "Rotty never stops stacking dollars for you!"
                />
                <Feature 
                    img = {verifiedMining}
                    h3 = "2% Max Wallet and 1% Max Transaction Protection"
                    p = "Rotty never stops protecting you and your wallet from BOTS!"
                />
                <Feature 
                    img = {fastestMiner}
                    h3 = "Marketing Built In"
                    p = "With Our Rotty Woof, all degens will hear about Rotty!"
                />
                <Feature 
                    img = {secureTransactions}
                    h3 = "LP Locked"
                    p = "Rotty guards the liquidity making sure, this is rug proof!"
                />
            </div>
        </section>
    )
}

export default TheCoinSection
