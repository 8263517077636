import React from 'react'
import privacy from '../Images/privacy-preserving.png'

function Howtobuy() {
    return (
        <>
        <section className="rewards">
            <div className="content">
                <div className="howtobuy-content">
                    <div className="howtobuy-text">
                        <h1>How to Buy</h1>
                        <p>
                    
                            

                                <b>Create A Wallet</b>
                                <br></br>

                                BEP20 – Download the MetaMask Wallet, and create a wallet.

                                If your MetaMask does not come with Binance Smart Chain pre-installed, please click here, for instructions on how to add the Binance Smart Chain network to your wallet.

                                Make sure to write down your recovery/seed phrase and password and keep them private. No one in the community will ever need your recovery/seed phrase.
                               
                                <br></br> 

                        </p>

                        <p>
                               
                                <b>Make $Rotty Visible In Your Wallet</b>

                                <br></br>


                                

                                BEP20 – Open Your MetaMask, make sure that You’re on the Smart Chain network, and click the “Add Token” text, at the bottom of the “Assets” screen. The “Custom Token” screen will load, and You will paste the BEP20 $Rotty contract address that is listed above
                                
                        </p>
                                
                            

                        <p>

                               <b>Funding Your Wallet</b> 

                               <br></br>

                                There are multiple ways to fund your MetaMask wallet, join our Telegram community and ask for suggestions to best suit your needs. To purchase the Baby Ripple, you need to fund your wallet with BNB.

                                **Please note that you will need excess amounts BNB to pay for the transaction 

                                <br></br>
                        </p> <p>          

                                <b>Purchasing $Rotty </b>
                                <br></br>

                                There are multiple ways to purchase $Rotty you can use Pancakeswap, Poocoin, or Dextools to Swap BNB for Rotty. The tax is 12% so put your slippage on 15%. Always put, 001 behind your amount of BabyXRP before you are gonna make the trade! join our Telegram community and ask our team if you can’t figure it out.


                        </p>
                    </div>

                </div>
            </div>
        </section>
        </>
    )
}

export default Howtobuy
